import React, { useState } from 'react';

import cover from '../../assets/images/shortcodes/business/musteralbum.png';

const hookUrl = 'https://hooks.zapier.com/hooks/catch/229626/3ul88fq/';

function BusinessMusteralbum(props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [submitState, setSubmitState] = useState(0);

  const heading = props.heading || 'Kostenloses Musteralbum in dein Postfach';
  const subline = props.subline || 'So könnte euer Stickeralbum aussehen! Freue dich auf Inspiration.';
  const image = cover;

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmitState(1);
    try {
      await fetch(hookUrl, {
        method: 'POST',
        body: JSON.stringify({
          name,
          email,
          utm_source: window.Cookies.utm_source,
          utm_medium: window.Cookies.utm_medium,
          utm_campaign: window.Cookies.utm_campaign,
          utm_content: window.location.pathname,
        }),
      });
      setSubmitState(2);
    } catch (err) {
      setSubmitState(-1);
    }
  }

  return (
    <div className="shortcode-cta ebook-section">
      <div className="ebook-content">
        <h2>{heading}</h2>
        {(submitState === 0 || submitState === -1) && (
          <div>
            <p>{subline}</p>
            <form onSubmit={handleSubmit}>
              <div className="form-control">
                <input
                  required
                  type="text"
                  placeholder="Name Unternehmen"
                  value={name}
                  onChange={({ target: { value } }) => setName(value)}
                />
              </div>
              <div className="form-control">
                <input
                  required
                  type="email"
                  placeholder="E-Mail-Adresse"
                  value={email}
                  onChange={({ target: { value } }) => setEmail(value)}
                />
              </div>
              <div className="form-control">
                <label className="checkbox">
                  <input type="checkbox" /> Ich möchte spannende Inhalte von
                  Stickerstars per E-Mail erhalten. Diese Einwilligung kann ich
                  jederzeit widerrufen.
                </label>
              </div>
              <div className="form-control">
                <button
                  disabled={submitState === 1}
                  type="submit"
                  className="button"
                >
                  abschicken
                </button>
              </div>
              {submitState === -1 && (
                <div className="alert">
                  Das hat leider nicht funktioniert! Bitte versuche es noch
                  einmal.
                </div>
              )}
            </form>
          </div>
        )}
        {submitState === 2 && (
          <div className="form-control">
            <p>Dein Musteralbum wartet im Posteingang!</p>
            <p>
              Weitere Informationen zum Sammelerlebnis für Unternehmen findest
              du <a href="https://business.stickerstars.de">hier</a>.
            </p>
          </div>
        )}
      </div>
      <div className="ebook-image">
        <img src={image} alt="Stickerstars Business Musteralbum Mockup" />
      </div>
    </div>
  );
}

BusinessMusteralbum.defaultProps = {};

BusinessMusteralbum.propTypes = {};

export default BusinessMusteralbum;
