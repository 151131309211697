import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  EmailIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

const SocialShare = ({ shareUrl }) => (
  <div className="social-share">
    <b>Beitrag teilen auf</b>
    <div>
      <TwitterShareButton url={shareUrl}><TwitterIcon size={50} round /></TwitterShareButton>
      <FacebookShareButton url={shareUrl}><FacebookIcon size={50} round /></FacebookShareButton>
      <EmailShareButton url={shareUrl}><EmailIcon size={50} round /></EmailShareButton>
      <WhatsappShareButton url={shareUrl}><WhatsappIcon size={50} round /></WhatsappShareButton>
    </div>
  </div>
);

export default SocialShare;
