import React from 'react';
import '../assets/styling/post.scss';
import { graphql, Link } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import Footer from '../components/Footer';
import Header from '../components/Header';
import Head from '../components/Head';
import SocialShare from '../components/SocialShare';
import {
  Youtube, CTA, Ebook, CustomCTA, CustomTable,
} from '../components/Shortcodes';
import businessShortCodes from '../components/business';
import RelatedPosts from '../components/RelatedPosts';

const slug = (str) => str
  .toLowerCase()
  .replace(/ /g, '-')
  .replace(/[^\w-]+/g, '');

const heading = (Tag) => (props) => {
  const findText = (children) => {
    if (typeof children === 'string') {
      return children;
    }

    if (Array.isArray(children)) {
      return children.map(findText).join('-');
    }

    return findText(children.props.children);
  };

  const text = findText(props.children);

  return (
    <Tag {...props} id={props.id || slug(text)}>
      <a href={`#${props.id || slug(text)}`}>{props.children}</a>
    </Tag>
  );
};

const shortcodes = {
  Youtube,
  CTA,
  Ebook,
  CustomCTA,
  CustomTable,
  h1: heading('h1'),
  h2: heading('h2'),
  h3: heading('h3'),
  h4: heading('h4'),
  h5: heading('h5'),
  h6: heading('h6'),
  ...businessShortCodes,
};

function ToC({ headings }) {
  return (
    <div className="toc">
      <h3>Inhaltsverzeichnis</h3>
      <ul>
        {headings
          .filter((_heading) => _heading.depth !== 1 && _heading.value !== '')
          .map((_heading) => (
            <li key={_heading.value}>
              <Link to={`#${slug(_heading.value)}`}>{_heading.value}</Link>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default function Template({ data, location: { pathname } }) {
  const { mdx: post, relatedPosts } = data;
  const { frontmatter } = post;
  const thumbnail = getSrc(frontmatter.thumbnail.childImageSharp);

  return (
    <MDXProvider
      components={{
        ...shortcodes,
        TableOfContents: (props) => <ToC {...props} headings={post.headings} />,
      }}
    >
      <Head
        title={frontmatter.title}
        description={frontmatter.description}
        thumbnail={thumbnail}
        category={post.frontmatter.category}
      />
      <Header path={pathname} category={post.frontmatter.category} />
      <div className="post-detail">
        <main>
          <article>
            <h1>{post.frontmatter.title}</h1>
            <p className="post-meta">
              von
              {' '}
              <b>{frontmatter.author}</b>
              {' '}
              /
              {' '}
              {frontmatter.date}
            </p>
            <MDXRenderer>{post.body}</MDXRenderer>
            <SocialShare
              shareUrl={`https://blog.stickerstars.de/${post.frontmatter.category}/${post.frontmatter.slug}`}
            />
          </article>
        </main>
        <RelatedPosts relatedPosts={relatedPosts} />
      </div>
      <Footer />
    </MDXProvider>
  );
}

export const postQuery = graphql`
  query ($ref: String, $internalId: String) {
    mdx(frontmatter: { id: { eq: $ref } }) {
      body
      headings {
        depth
        value
      }
      frontmatter {
        title
        description
        id
        category
        slug
        author
        date(formatString: "D.M.YYYY")
        thumbnail {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
        }
      }
    }
    relatedPosts: relatedMdxs(parent: { id: { eq: $internalId } }) {
      posts {
        permalink
        frontmatter {
          title
          thumbnail {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
