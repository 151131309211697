import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import businessEbook from '../assets/images/shortcodes/ebook/business.png';
import weddingEbook from '../assets/images/shortcodes/ebook/wedding.png';
import clubsEbook from '../assets/images/shortcodes/ebook/clubs.png';

export const Youtube = ({ id }) => (
  <div>
    <iframe
      className="yt-iframe"
      src={`https://www.youtube-nocookie.com/embed/${id}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </div>
);

export const CTA = ({ type, heading, subline }) => {
  const assetLookUp = {
    business: {
      heading: heading || 'Kein Business as usual',
      subline: subline || 'Mit dem einzigartigen Stickerstars-Sammelerlebnis',
      url: 'https://business.stickerstars.de',
    },
    wedding: {
      heading: heading || 'Eure Hochzeit unvergesslich',
      subline: subline || 'Mit dem einzigartigen Stickerstars-Sammelerlebnis',
      url: 'https://wedding.stickerstars.de',
    },
    clubs: {
      heading: heading || 'Stickerstars-Sammelerlebnis',
      subline: subline || 'Das Sammelalbum für lokale Stars',
      url: 'https://www.stickerstars.de/so-gehts',
    },
    fw: {
      heading: heading || 'Stickerstars-Sammelerlebnis',
      subline: subline || 'Das Sammelalbum für deine Feuerwehr',
      url: 'https://www.stickerstars.de/feuerwehr',
    },
  };

  const { url } = assetLookUp[type];

  return (
    <div className={`shortcode-cta ${type}`}>
      <h1>{assetLookUp[type].heading}</h1>
      <p className="subheading">{assetLookUp[type].subline}</p>
      {type === 'business' && (
      <ul>
        <li><a href={url} alt="Designer Starten" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/shortcodes/cta-business/1.jpeg" alt="Album Cover 1" /></a></li>
        <li><a href={url} alt="Designer Starten" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/shortcodes/cta-business/3.jpeg" alt="Album Cover 2" /></a></li>
        <li><a href={url} alt="Designer Starten" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/shortcodes/cta-business/4.jpeg" alt="Album Cover 3" /></a></li>
        <li><a href={url} alt="Designer Starten" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/shortcodes/cta-business/5.jpeg" alt="Album Cover 4" /></a></li>
      </ul>
      )}
      {type === 'wedding' && (
      <ul>
        <li><a href={url} alt="Designer Starten" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/shortcodes/cta-wedding/60fe80bb3b1f1b0d1654f175_Emma_Noah.jpg" alt="Album Cover 2" /></a></li>
        <li><a href={url} alt="Designer Starten" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/shortcodes/cta-wedding/60fe80eec119d4790c396896_Jan_Diana.jpg" alt="Album Cover 3" /></a></li>
        <li><a href={url} alt="Designer Starten" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/shortcodes/cta-wedding/60fe810ec119d4ab283968b0_Sarah_Sam.jpg" alt="Album Cover 4" /></a></li>
        <li><a href={url} alt="Designer Starten" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/shortcodes/cta-wedding/60fe81072c973b75410c6dc7_Anna_Ethan.jpg" alt="Album Cover 5" /></a></li>
      </ul>
      )}
      {type === 'clubs' && (
      <ul>
        <li><a href={url} alt="Designer Starten" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/shortcodes/cta-clubs/clubs_1.jpeg" alt="Album Cover 1" /></a></li>
        <li><a href={url} alt="Designer Starten" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/shortcodes/cta-clubs/clubs_3.jpeg" alt="Album Cover 3" /></a></li>
        <li><a href={url} alt="Designer Starten" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/shortcodes/cta-clubs/clubs_2.jpeg" alt="Album Cover 2" /></a></li>
        <li><a href={url} alt="Designer Starten" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/shortcodes/cta-clubs/clubs_4.jpeg" alt="Album Cover 4" /></a></li>
      </ul>
      )}
      {type === 'fw' && (
      <ul>
        <li><a href={url} alt="Designer Starten" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/shortcodes/cta-fw/fw_1.jpg" alt="Album Cover 1" /></a></li>
        <li><a href={url} alt="Designer Starten" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/shortcodes/cta-fw/fw_2.jpg" alt="Album Cover 2" /></a></li>
        <li><a href={url} alt="Designer Starten" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/shortcodes/cta-fw/fw_3.jpg" alt="Album Cover 3" /></a></li>
        <li><a href={url} alt="Designer Starten" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/shortcodes/cta-fw/fw_4.jpg" alt="Album Cover 4" /></a></li>
      </ul>
      )}
      <div className="cta-btn">
        <a href={url} className="button" target="_blank" rel="noreferrer">Jetzt starten</a>
      </div>
    </div>
  );
};

export function Ebook({ type, heading, subline }) {
  const assets = {
    business: {
      url: 'https://business.stickerstars.de/unternehmen-guide-ebook',
      heading: 'Ein besseres Miteinander im Unternehmen',
      subline: 'In 10 informativen Artikeln erfahrt ihr, welche Potenziale in eurem Business stecken. Jetzt herunterladen und zum Experten werden.',
      image: businessEbook,
    },
    wedding: {
      url: 'https://wedding.stickerstars.de/hochzeit-guide-ebook',
      heading: 'Der XXL-Guide für eure Hochzeit',
      subline: 'Von der perfekten Checkliste bis zu den außergewöhnlichsten Hochzeitsspielen - erfahrt auf 25 Seiten alles, was ihr für eure Traumhochzeit wissen müsst.',
      image: weddingEbook,
    },
    clubs: {
      url: 'https://www.stickerstars.de/verein-guide-ebook',
      heading: 'Der ultimative Guide für euren Verein ',
      subline: 'Von der Mitgliedergewinnung bis hin zur Verwaltung - In 8 informativen Kapiteln erfahrt ihr, warum eure Vereinsarbeit noch Potenzial hat.',
      image: clubsEbook,
    },
  }[type];

  return (
    <div className="shortcode-cta ebook-section">
      <div className="ebook-content">
        <h3>{heading || assets.heading}</h3>
        <p>{subline || assets.subline}</p>
      </div>
      <div className="ebook-image">
        <img src={assets.image} alt={`Stickerstars ${type} E-Book cover preview`} />
        <a href={assets.url} target="_blank" className="button">Kostenlos herunterladen</a>
      </div>
    </div>
  )
}

export const CustomCTA = ({
  heading,
  subline,
  buttonUrl,
  buttonLabel,
  type,
}) => (
  <div className={`shortcode-cta ${type}`}>
    <h1>{heading}</h1>
    <p className="subheading">{subline}</p>
    <div className="cta-btn">
      <a href={buttonUrl} target="_blank" className="button">
        {buttonLabel}
      </a>
    </div>
  </div>
);

export const CustomTable = ({
  html,
}) => (
  <div
    dangerouslySetInnerHTML={{ __html: html }}
  />
);
