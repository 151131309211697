import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const RelatedPosts = ({ relatedPosts }) => {
  const { posts } = relatedPosts;

  return (
    <div className="latest-articles">
      <h2>Auch interessant</h2>
      <div className="posts-grid">
        {posts.slice(0, 9).map((n) => {
          const { frontmatter } = n;
          const image = frontmatter.thumbnail
            ? getImage(frontmatter.thumbnail.childImageSharp)
            : null;

          return (
            <div key={`article-${frontmatter.title}`}>
              <a href={n.permalink}>
                {image && <GatsbyImage image={image} alt={frontmatter.title} />}
                <h3>{frontmatter.title}</h3>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RelatedPosts;
